/** Adds nested create feature */
export type AdminsPageParent = {
  id: number
  artists: string
  news: string
}

/** Adds nested create feature */
export type AdminsPageParentRequest = {
  /** @minLength 1 */
  artists: string
  /** @minLength 1 */
  news: string
}

export type AnchorTypeEnum =
  | 'simplepage'
  | 'attachment'
  | 'news'
  | 'artist'
  | 'url'
  | 'calendly'

/** * `article` - Article */
export type ArticleTypeEnum = 'article'

export type ArtistEventDate = {
  id: number
  fixed_date: string
  /** @format date-time */
  date: string
  name: string
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  place: PublicPlace
}

export type ArtistWithNearestEvent = {
  id: string
  title: string
  country: string
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  image: PublicImage
  nearest_event: ArtistEventDate
  slug: string
}

export type BlankEnum = ''

/**
 * * `orange` - Pomarańczowy
 * * `blue` - Niebieski
 * * `pink` - Różowy
 * * `violet` - Fioletowy
 * * `green` - Zielony
 */
export type ColorD66Enum = 'orange' | 'blue' | 'pink' | 'violet' | 'green'

export type CustomAuthToken = {
  token: string
}

export type CustomAuthTokenRequest = {
  /**
   * Hasło
   * @minLength 1
   */
  password: string
  /** @minLength 1 */
  email: string
}

/**
 * * `no_margin` - Bez marginesu
 * * `small` - Mały
 * * `large` - Duży
 */
export type HorizontalMarginEnum = 'no_margin' | 'small' | 'large'

export type LanguageChoice = {
  label: string
  /**
   * * `pl` - PL
   * * `en` - EN
   */
  value: ValueEnum
}

export type LineUpArtist = {
  id: string
  title: string
  slug: string
  country: string
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  image: PublicImage
}

export type LineUpEvent = {
  id: string
  event_date_id: number
  name: string
  /** @format date-time */
  date: string
  /** @format date */
  fixed_date: string
  artist: LineUpArtist
}

export type LineUpItem = {
  /** @format date */
  date: string
  stages: LineUpStage[]
}

export type LineUpStage = {
  place_id: string
  /**
   * * `orange` - Pomarańczowy
   * * `blue` - Niebieski
   * * `pink` - Różowy
   * * `violet` - Fioletowy
   * * `green` - Zielony
   */
  place_color: PlaceColorEnum
  place_name: string
  events: LineUpEvent[]
}

/**
 * * `small` - Mały
 * * `medium` - Średni
 * * `large` - Duży
 * * `none` - Brak
 */
export type MarginBottomEnum = 'small' | 'medium' | 'large' | 'none'

/**
 * * `small` - Mały
 * * `medium` - Średni
 * * `large` - Duży
 * * `none` - Brak
 */
export type MarginTopEnum = 'small' | 'medium' | 'large' | 'none'

export type PagesSitemapResponse = {
  simplepage: PublicSitemap[]
  article: PublicSitemap[]
}

export type PaginatedPublicArtistListList = {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: PublicArtistList[]
  /** @example 15 */
  page_size?: number
}

export type PaginatedPublicGalleryImageList = {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: PublicGalleryImage[]
  /** @example 15 */
  page_size?: number
}

export type PaginatedPublicGalleryList = {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: PublicGallery[]
  /** @example 15 */
  page_size?: number
}

export type PaginatedPublicNewsList = {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: PublicNews[]
  /** @example 15 */
  page_size?: number
}

export type PaginatedPublicPlaceList = {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: PublicPlace[]
  /** @example 15 */
  page_size?: number
}

export type PaginatedPublicSimplePageResponseList = {
  /** @example 123 */
  count?: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null
  results?: PublicSimplePageResponse[]
  /** @example 15 */
  page_size?: number
}

/** Adds nested create feature */
export type PatchedAdminsPageParentRequest = {
  /** @minLength 1 */
  artists?: string
  /** @minLength 1 */
  news?: string
}

/**
 * * `orange` - Pomarańczowy
 * * `blue` - Niebieski
 * * `pink` - Różowy
 * * `violet` - Fioletowy
 * * `green` - Zielony
 */
export type PlaceColorEnum = 'orange' | 'blue' | 'pink' | 'violet' | 'green'

export type PublicArticleListWidget = {
  margin_top?: MarginTopEnum | BlankEnum
  margin_bottom?: MarginBottomEnum | BlankEnum
  id: string
  banner_title: string
  banner_text: string
  banner_cta_button: PublicCtaButton | null
  banner_background: PublicImage | null
  banner_with_white_text?: boolean
  is_banner_visible?: boolean
  banner_full_width?: boolean
  /** * `article` - Article */
  article_type: ArticleTypeEnum
  all_available_tags: PublicTag[]
  /** @default "ArticleListWidget" */
  resourcetype?: ResourcetypeEnum
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PublicArtist = {
  id: string
  slug: string
  /**
   * Slug
   * @maxLength 50
   * @pattern ^[-a-zA-Z0-9_]+$
   */
  slug_pl?: string
  /**
   * Slug
   * @maxLength 50
   * @pattern ^[-a-zA-Z0-9_]+$
   */
  slug_en?: string
  title: string
  widgets: PublicWidget[]
  meta_description: string
  /**
   * Zmodyfikowany
   * @format date-time
   */
  modified: string
  meta_keywords: string
  country: string
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  image: PublicImage
  lead: string
  description: string
  nearest_event: ArtistEventDate
  pixels_disabled?: boolean
  site_title: string
  og_title: string
  og_description: string
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  og_image: PublicImage
  breadcrumb: PublicBreadcrumb[]
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PublicArtistList = {
  id: string
  slug: string
  /**
   * Slug
   * @maxLength 50
   * @pattern ^[-a-zA-Z0-9_]+$
   */
  slug_pl?: string
  /**
   * Slug
   * @maxLength 50
   * @pattern ^[-a-zA-Z0-9_]+$
   */
  slug_en?: string
  title: string
  widgets: PublicWidget[]
  meta_description: string
  /**
   * Zmodyfikowany
   * @format date-time
   */
  modified: string
  meta_keywords: string
  country: string
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  image: PublicImage
  lead: string
  description: string
  nearest_event: ArtistEventDate
  pixels_disabled?: boolean
  site_title: string
  og_title: string
  og_description: string
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  og_image: PublicImage
}

export type PublicArtistsListWidget = {
  margin_top?: MarginTopEnum | BlankEnum
  margin_bottom?: MarginBottomEnum | BlankEnum
  id: string
  title: string
  artists_link: PublicLink | null
  artists: ArtistWithNearestEvent[]
  /** @default "ArtistsListWidget" */
  resourcetype?: ResourcetypeEnum
}

export type PublicBlockquoteWidget = {
  margin_top?: MarginTopEnum | BlankEnum
  margin_bottom?: MarginBottomEnum | BlankEnum
  id: string
  quote: string
  /** @maxLength 200 */
  signature?: string
  /** @default "BlockquoteWidget" */
  resourcetype?: ResourcetypeEnum
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PublicBreadcrumb = {
  slug: string
  title: string
}

export type PublicContactFormWidget = {
  margin_top?: MarginTopEnum | BlankEnum
  margin_bottom?: MarginBottomEnum | BlankEnum
  id: string
  title: string
  description: string
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  image: PublicImage
  agreement_text: string
  is_text_left?: boolean
  /** @default "ContactFormWidget" */
  resourcetype?: ResourcetypeEnum
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PublicCtaButton = {
  /**
   * * `primary` - Podstawowy
   * * `primary_light` - Podstawowy Jasny
   * * `primary_dark` - Podstawowy Ciemny
   * * `secondary` - Drugorzędny
   * * `secondary_light` - Drugorzędny Jasny
   * * `secondary_orange` - Drugorzędny Pomarańczowy
   */
  color?: PublicCtaButtonColorEnum
  link?: PublicLink | null
  title: string
}

/**
 * * `primary` - Podstawowy
 * * `primary_light` - Podstawowy Jasny
 * * `primary_dark` - Podstawowy Ciemny
 * * `secondary` - Drugorzędny
 * * `secondary_light` - Drugorzędny Jasny
 * * `secondary_orange` - Drugorzędny Pomarańczowy
 */
export type PublicCtaButtonColorEnum =
  | 'primary'
  | 'primary_light'
  | 'primary_dark'
  | 'secondary'
  | 'secondary_light'
  | 'secondary_orange'

export type PublicCtaButtonWidget = {
  margin_top?: MarginTopEnum | BlankEnum
  margin_bottom?: MarginBottomEnum | BlankEnum
  id: string
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  cta_button: PublicCtaButton
  /** @default "CtaButtonWidget" */
  resourcetype?: ResourcetypeEnum
}

export type PublicCustomerLogo = {
  id: number
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  logotype: PublicImage
  link?: PublicLink | null
}

export type PublicCustomersRangeWidget = {
  id: number
  /**
   * @min 0
   * @max 2147483647
   */
  min_users?: number
  /**
   * @min 0
   * @max 2147483647
   */
  max_users?: number | null
  activation_fee: string
  whats_included: string
  monthly_payment_price: string
  yearly_payment_price: string
  monthly_cta: PublicCtaButton | null
  yearly_cta: PublicCtaButton | null
  selected_by_default?: boolean
}

export type PublicFaqItem = {
  id: number
  question: string
  answer: string
}

export type PublicFaqSection = {
  subtitle: string
  items: PublicFaqItem[]
}

export type PublicFaqWidget = {
  margin_top?: MarginTopEnum | BlankEnum
  margin_bottom?: MarginBottomEnum | BlankEnum
  id: string
  title: string
  sections: PublicFaqSection[]
  /** @default "FaqWidget" */
  resourcetype?: ResourcetypeEnum
}

export type PublicFootnotes = {
  id: number
  text: string
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PublicGallery = {
  id: string
  name: string
  /** Name */
  name_pl: string
  /** Name */
  name_en: string
  images: PublicImage[]
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PublicGalleryImage = {
  id: number
  gallery: string
  image: PublicImage
  sort_value: number
}

export type PublicGalleryWidget = {
  margin_top: MarginTopEnum
  margin_bottom: MarginBottomEnum
  id: string
  title: string
  link: PublicLink
  selected_gallery: string
  available_galleries: StringOption[]
  /** @default "GalleryWidget" */
  resourcetype?: ResourcetypeEnum
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PublicImage = {
  id: string
  images: string
  alt: string
  title: string
  /** @format uri */
  file?: string
  height: number | null
  width: number | null
  ppoi_tuple: string
  /** @maxLength 7 */
  dominant_color?: string
  has_transparency?: boolean
}

export type PublicImageWidget = {
  margin_top?: MarginTopEnum | BlankEnum
  margin_bottom?: MarginBottomEnum | BlankEnum
  id: string
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  image: PublicImage
  caption: string
  /**
   * * `no_margin` - Bez marginesu
   * * `small` - Mały
   * * `large` - Duży
   */
  horizontal_margin?: HorizontalMarginEnum
  /** @default "ImageWidget" */
  resourcetype?: ResourcetypeEnum
}

export type PublicImageWithMultipleLinksWidget = {
  margin_top?: MarginTopEnum | BlankEnum
  margin_bottom?: MarginBottomEnum | BlankEnum
  id: string
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  image: PublicImage
  map_areas: PublicMapArea[]
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  image_tablet: PublicImage
  map_areas_tablet: PublicMapArea[]
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  image_mobile: PublicImage
  map_areas_mobile: PublicMapArea[]
  /** @default "ImageWithMultipleLinksWidget" */
  resourcetype?: ResourcetypeEnum
}

export type PublicLatestNewsWidget = {
  margin_top?: MarginTopEnum | BlankEnum
  margin_bottom?: MarginBottomEnum | BlankEnum
  id: string
  title: string
  news_link: PublicLink | null
  /** @default "LatestNewsWidget" */
  resourcetype?: ResourcetypeEnum
}

export type PublicLineUpWidget = {
  margin_top?: MarginTopEnum | BlankEnum
  margin_bottom?: MarginBottomEnum | BlankEnum
  id: string
  title: string
  items: LineUpItem[]
  /** @default "LineUpWidget" */
  resourcetype?: ResourcetypeEnum
}

export type PublicLink = {
  anchor_type: AnchorTypeEnum
  anchor_id: string
  slug: string
  target_blank?: boolean
  widget?: string | null
  url: string
  video_id: string
  video_is_vimeo: string
  label: string
  /** @format uri */
  attachment_file: string
  category: string
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PublicMainMenu = {
  id: string
  items: PublicMenuItem[]
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  primary_cta_button: PublicCtaButton
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  secondary_cta_button: PublicCtaButton
  /** @maxLength 15 */
  festival_date?: string
  /** @maxLength 50 */
  festival_place?: string
}

export type PublicMapArea = {
  id: string
  /**
   * @format uri
   * @maxLength 200
   */
  url?: string
  title?: string
  /**
   * @min 0
   * @max 2147483647
   */
  x: number
  /**
   * @min 0
   * @max 2147483647
   */
  y: number
  /**
   * @min 0
   * @max 2147483647
   */
  width: number
  /**
   * @min 0
   * @max 2147483647
   */
  height: number
}

export type PublicMenuItem = {
  label: string
  link?: PublicLink | null
  items: PublicSecondLevelMenuItem[] | null
  is_after_divider?: boolean
}

export type PublicMessage = {
  /** @maxLength 128 */
  first_name: string
  /** @maxLength 128 */
  last_name: string
  /**
   * @format email
   * @maxLength 254
   */
  email: string
  /** @maxLength 500 */
  text: string
}

export type PublicMessageRequest = {
  /**
   * @minLength 1
   * @maxLength 128
   */
  first_name: string
  /**
   * @minLength 1
   * @maxLength 128
   */
  last_name: string
  /**
   * @format email
   * @minLength 1
   * @maxLength 254
   */
  email: string
  /**
   * @minLength 1
   * @maxLength 500
   */
  text: string
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PublicNews = {
  id: string
  slug: string
  /**
   * Slug
   * @maxLength 50
   * @pattern ^[-a-zA-Z0-9_]+$
   */
  slug_pl?: string
  /**
   * Slug
   * @maxLength 50
   * @pattern ^[-a-zA-Z0-9_]+$
   */
  slug_en?: string
  title: string
  page_header: string
  lead: string
  widgets: PublicWidget[]
  /**
   * Data publikacji
   * @format date-time
   */
  publication_date?: string | null
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  image: PublicImage
  meta_keywords: string
  highlighted_section_name: string
  cta_button: PublicCtaButton | null
  content: string
  pixels_disabled?: boolean
  site_title: string
  meta_description: string
  og_title: string
  og_description: string
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  og_image: PublicImage
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PublicNewsDetail = {
  id: string
  slug: string
  /**
   * Slug
   * @maxLength 50
   * @pattern ^[-a-zA-Z0-9_]+$
   */
  slug_pl?: string
  /**
   * Slug
   * @maxLength 50
   * @pattern ^[-a-zA-Z0-9_]+$
   */
  slug_en?: string
  title: string
  page_header: string
  lead: string
  widgets: PublicWidget[]
  /**
   * Data publikacji
   * @format date-time
   */
  publication_date?: string | null
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  image: PublicImage
  meta_keywords: string
  highlighted_section_name: string
  cta_button: PublicCtaButton | null
  content: string
  pixels_disabled?: boolean
  site_title: string
  meta_description: string
  og_title: string
  og_description: string
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  og_image: PublicImage
  /** @default "news" */
  link_to_all: string
  breadcrumb: PublicBreadcrumb[]
}

export type PublicNewsletterAgreement = {
  id: string
  text: string
  is_required?: boolean
}

export type PublicNewsletterSettings = {
  id: string
  agreement: PublicNewsletterAgreement
}

export type PublicNewsletterWidget = {
  margin_top?: MarginTopEnum | BlankEnum
  margin_bottom?: MarginBottomEnum | BlankEnum
  id: string
  title: string
  widget_title: string
  description: string
  settings: PublicNewsletterSettings
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  image_desktop: PublicImage
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  image_tablet: PublicImage
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  image_mobile: PublicImage
  terms_of_service: PublicLink
  success_message: string
  success_header: string
  /** @default "NewsletterWidget" */
  resourcetype?: ResourcetypeEnum
}

export type PublicOurCustomersWidget = {
  margin_top?: MarginTopEnum | BlankEnum
  margin_bottom?: MarginBottomEnum | BlankEnum
  id: string
  title: string
  logotypes: PublicCustomerLogo[]
  with_background?: boolean
  /** @default "OurCustomersWidget" */
  resourcetype?: ResourcetypeEnum
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PublicPlace = {
  id: string
  name: string
  /** @pattern ^[-a-zA-Z0-9_]+$ */
  slug: string
  /**
   * * `orange` - Pomarańczowy
   * * `blue` - Niebieski
   * * `pink` - Różowy
   * * `violet` - Fioletowy
   * * `green` - Zielony
   */
  color?: ColorD66Enum
}

export type PublicPricingWidget = {
  margin_top?: MarginTopEnum | BlankEnum
  margin_bottom?: MarginBottomEnum | BlankEnum
  id: string
  title: string
  subtitle: string
  package_title: string
  tooltip_visible?: boolean
  tooltip_button_text: string
  tooltip_title: string
  tooltip_text: string
  ranges: PublicCustomersRangeWidget[]
  /** @default "PricingWidget" */
  resourcetype?: ResourcetypeEnum
}

export type PublicSecondLevelMenuItem = {
  label: string
  link?: PublicLink | null
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PublicSimplePageResponse = {
  id: string
  slug: string
  /**
   * Slug
   * @maxLength 50
   * @pattern ^[-a-zA-Z0-9_]+$
   */
  slug_pl?: string
  /**
   * Slug
   * @maxLength 50
   * @pattern ^[-a-zA-Z0-9_]+$
   */
  slug_en?: string
  title: string
  category: string
  pixels_disabled?: boolean
  widgets: PublicWidget[]
  meta_description: string
  site_title: string
  og_title: string
  og_description: string
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  og_image: PublicImage
  /**
   * Zmodyfikowany
   * @format date-time
   */
  modified: string
  meta_keywords: string
  category_pl: string
  category_en: string
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PublicSiteSettings = {
  id: string
  /**
   * @format uri
   * @maxLength 200
   */
  facebook_url?: string
  /**
   * @format uri
   * @maxLength 200
   */
  twitter_url?: string
  /**
   * @format uri
   * @maxLength 200
   */
  youtube_url?: string
  /**
   * @format uri
   * @maxLength 200
   */
  instagram_url?: string
  /**
   * @format uri
   * @maxLength 200
   */
  linkedin_url?: string
  /**
   * @format uri
   * @maxLength 200
   */
  spotify_url?: string
  /**
   * @format uri
   * @maxLength 200
   */
  apple_url?: string
  /**
   * @format uri
   * @maxLength 200
   */
  google_url?: string
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  primary_logotype: PublicImage
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  secondary_logotype: PublicImage
  widget_margins: string
  privacy_policy_link: PublicLink
  contact_page_link: PublicLink
}

export type PublicSitemap = {
  /** @format date-time */
  modified: string
  slug_pl?: string
  category_pl?: string
  slug_en?: string
  category_en?: string
}

export type PublicSliderItem = {
  title: string
  link: PublicLink
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  image: PublicImage
  artist_country: string
  text: string
  background_text: string
}

export type PublicSliderWidget = {
  margin_top?: MarginTopEnum | BlankEnum
  margin_bottom?: MarginBottomEnum | BlankEnum
  id: string
  items: PublicSliderItem[]
  /** @default "SliderWidget" */
  resourcetype?: ResourcetypeEnum
}

export type PublicSubscribe = {
  /** @format email */
  email: string
  /** @default false */
  agreement?: boolean
  widget: string
}

export type PublicSubscribeRequest = {
  /**
   * @format email
   * @minLength 1
   */
  email: string
  /** @default false */
  agreement?: boolean
  /** @minLength 1 */
  widget: string
}

export type PublicTag = {
  id: string
  /**
   * Nazwa
   * @maxLength 30
   */
  name: string
  /**
   * * `blue` - Niebieski
   * * `green` - Zielony
   * * `navy` - Granatowy
   * * `yellow` - Żółty
   * * `blue-light` - Jasnoniebieski
   * * `green-light` - Jasnozielony
   */
  color?: PublicTagColorEnum
  protected_tag?: boolean
}

/**
 * * `blue` - Niebieski
 * * `green` - Zielony
 * * `navy` - Granatowy
 * * `yellow` - Żółty
 * * `blue-light` - Jasnoniebieski
 * * `green-light` - Jasnozielony
 */
export type PublicTagColorEnum =
  | 'blue'
  | 'green'
  | 'navy'
  | 'yellow'
  | 'blue-light'
  | 'green-light'

export type PublicTextWidget = {
  margin_top?: MarginTopEnum | BlankEnum
  margin_bottom?: MarginBottomEnum | BlankEnum
  id: string
  text: string
  /** @default "TextWidget" */
  resourcetype?: ResourcetypeEnum
}

export type PublicTicket = {
  id: number
  title: string
  subtitle: string
  description: string
  /**
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  price: string
  link: PublicLink
}

export type PublicTicketType = {
  id: number
  title: string
  description: string
  /**
   * * `blue` - niebieski
   * * `orange` - pomarańczowy
   * * `pink` - różowy
   */
  color?: PublicTicketTypeColorEnum
  /**
   * * `sold_out` - Wyprzedane
   * * `on_sale` - W sprzedaży
   * * `empty` - Pusty
   */
  status?: StatusEnum
  tickets: PublicTicket[]
  footnotes: PublicFootnotes[]
}

/**
 * * `blue` - niebieski
 * * `orange` - pomarańczowy
 * * `pink` - różowy
 */
export type PublicTicketTypeColorEnum = 'blue' | 'orange' | 'pink'

export type PublicTicketsWidget = {
  margin_top?: MarginTopEnum | BlankEnum
  margin_bottom?: MarginBottomEnum | BlankEnum
  id: string
  ticket_types: PublicTicketType[]
  title: string
  current_edition: string
  /** @default "TicketsWidget" */
  resourcetype?: ResourcetypeEnum
}

export type PublicTilesItem = {
  id: number
  title: string
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  image: PublicImage
  link: PublicLink
}

export type PublicTilesWidget = {
  margin_top?: MarginTopEnum | BlankEnum
  margin_bottom?: MarginBottomEnum | BlankEnum
  id: string
  title: string
  items: PublicTilesItem[]
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  cta_button: PublicCtaButton
  /** @default "TilesWidget" */
  resourcetype?: ResourcetypeEnum
}

export type PublicTweetEmbedSerializer = {
  margin_top?: MarginTopEnum | BlankEnum
  margin_bottom?: MarginBottomEnum | BlankEnum
  id: string
  /** @maxLength 20 */
  tweet_id: string
  /** @default "TweetEmbedWidget" */
  resourcetype?: ResourcetypeEnum
}

export type PublicUpcomingEventsWidget = {
  margin_top?: MarginTopEnum | BlankEnum
  margin_bottom?: MarginBottomEnum | BlankEnum
  id: string
  title: string
  allow_date_filter?: boolean
  allow_category_filter?: boolean
  /** @default "UpcomingEventsWidget" */
  resourcetype?: ResourcetypeEnum
}

export type PublicVideoWidget = {
  margin_top?: MarginTopEnum | BlankEnum
  margin_bottom?: MarginBottomEnum | BlankEnum
  id: string
  title: string
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  image: PublicImage
  video_video_id: string
  video_is_vimeo: string
  /** @default "VideoWidget" */
  resourcetype?: ResourcetypeEnum
}

export type PublicWidget =
  | PublicArticleListWidget
  | PublicArtistsListWidget
  | PublicBlockquoteWidget
  | PublicContactFormWidget
  | PublicCtaButtonWidget
  | PublicFaqWidget
  | PublicGalleryWidget
  | PublicImageWidget
  | PublicImageWithMultipleLinksWidget
  | PublicOurCustomersWidget
  | PublicLineUpWidget
  | PublicLatestNewsWidget
  | PublicNewsletterWidget
  | PublicPricingWidget
  | PublicSliderWidget
  | PublicTextWidget
  | PublicTicketsWidget
  | PublicTweetEmbedSerializer
  | PublicTilesWidget
  | PublicUpcomingEventsWidget
  | PublicVideoWidget

/**
 * * `ArticleListWidget` - ArticleListWidget
 * * `ArtistsListWidget` - ArtistsListWidget
 * * `BlockquoteWidget` - BlockquoteWidget
 * * `ContactFormWidget` - ContactFormWidget
 * * `CtaButtonWidget` - CtaButtonWidget
 * * `FaqWidget` - FaqWidget
 * * `GalleryWidget` - GalleryWidget
 * * `ImageWidget` - ImageWidget
 * * `ImageWithMultipleLinksWidget` - ImageWithMultipleLinksWidget
 * * `OurCustomersWidget` - OurCustomersWidget
 * * `LineUpWidget` - LineUpWidget
 * * `LatestNewsWidget` - LatestNewsWidget
 * * `NewsletterWidget` - NewsletterWidget
 * * `PricingWidget` - PricingWidget
 * * `SliderWidget` - SliderWidget
 * * `TextWidget` - TextWidget
 * * `TicketsWidget` - TicketsWidget
 * * `TweetEmbedWidget` - TweetEmbedWidget
 * * `TilesWidget` - TilesWidget
 * * `UpcomingEventsWidget` - UpcomingEventsWidget
 * * `VideoWidget` - VideoWidget
 */
export type ResourcetypeEnum =
  | 'ArticleListWidget'
  | 'ArtistsListWidget'
  | 'BlockquoteWidget'
  | 'ContactFormWidget'
  | 'CtaButtonWidget'
  | 'FaqWidget'
  | 'GalleryWidget'
  | 'ImageWidget'
  | 'ImageWithMultipleLinksWidget'
  | 'OurCustomersWidget'
  | 'LineUpWidget'
  | 'LatestNewsWidget'
  | 'NewsletterWidget'
  | 'PricingWidget'
  | 'SliderWidget'
  | 'TextWidget'
  | 'TicketsWidget'
  | 'TweetEmbedWidget'
  | 'TilesWidget'
  | 'UpcomingEventsWidget'
  | 'VideoWidget'

/**
 * * `sold_out` - Wyprzedane
 * * `on_sale` - W sprzedaży
 * * `empty` - Pusty
 */
export type StatusEnum = 'sold_out' | 'on_sale' | 'empty'

export type StringOption = {
  label: string
  value: string
}

/**
 * * `pl` - PL
 * * `en` - EN
 */
export type ValueEnum = 'pl' | 'en'

import type { AxiosInstance, AxiosRequestConfig, ResponseType } from 'axios'
import axios from 'axios'

export type QueryParamsType = Record<string | number, any>

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType
  /** request body */
  body?: unknown
}

export type RequestParams = Omit<
  FullRequestParams,
  'body' | 'method' | 'query' | 'path'
>

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void
  secure?: boolean
  format?: ResponseType
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain'
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private secure?: boolean
  private format?: ResponseType

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || ''
    })
    this.secure = secure
    this.format = format
    this.securityWorker = securityWorker
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  protected mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig
  ): AxiosRequestConfig {
    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {})
      }
    }
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem)
    } else {
      return `${formItem}`
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key]
      const propertyContent: any[] =
        property instanceof Array ? property : [property]

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File
        formData.append(
          key,
          isFileType ? formItem : this.stringifyFormItem(formItem)
        )
      }

      return formData
    }, new FormData())
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<T> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const responseFormat = format || this.format || undefined

    if (
      type === ContentType.FormData &&
      body &&
      body !== null &&
      typeof body === 'object'
    ) {
      body = this.createFormData(body as Record<string, unknown>)
    }

    if (
      type === ContentType.Text &&
      body &&
      body !== null &&
      typeof body !== 'string'
    ) {
      body = JSON.stringify(body)
    }

    return this.instance
      .request({
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type && type !== ContentType.FormData
            ? { 'Content-Type': type }
            : {})
        },
        params: query,
        responseType: responseFormat,
        data: body,
        url: path
      })
      .then(response => response.data)
  }
}

type Flatten<T> = T extends any[] ? T[number] : T
type ChangeTypeOfKeys<T extends object, Keys extends keyof T, NewType> = {
  [key in keyof T]: key extends Keys ? NewType : T[key]
}
type MapObjectKeysToTrue<ObjectType extends Record<PropertyKey, any>> = {
  [key in keyof ObjectType]?: ObjectType[key] extends Record<PropertyKey, any>
    ? true | MapObjectKeysToTrue<Flatten<ObjectType[key]>>
    : true
}
type FetchKeysType = { [key in PropertyKey]: true | FetchKeysType }
type FetchKeys<ResponseModel> = ResponseModel extends {
  count?: number
  results?: Array<infer DataModel>
}
  ? DataModel extends Record<PropertyKey, any>
    ? MapObjectKeysToTrue<DataModel>
    : DataModel
  : ResponseModel extends Record<PropertyKey, any>
  ? MapObjectKeysToTrue<Flatten<ResponseModel>>
  : ResponseModel

type NestedSafePick<ResponseModel, FetchKeysType> = ResponseModel extends Array<
  infer Model
>
  ? NestedSafePick<Model, FetchKeysType>[]
  : {
      [key in keyof FetchKeysType]: FetchKeysType[key] extends Record<
        PropertyKey,
        any
      >
        ? key extends keyof ResponseModel
          ? ResponseModel[key] extends Array<infer DataModel>
            ? NestedSafePick<DataModel, FetchKeysType[key]>[]
            : NestedSafePick<ResponseModel[key], FetchKeysType[key]>
          : never
        : key extends keyof ResponseModel
        ? ResponseModel[key] extends Array<infer DataModel>
          ? DataModel[]
          : ResponseModel[key]
        : never
    }

type PickKeys<ResponseModel, FetchKeysType> = {} extends FetchKeysType
  ? ResponseModel
  : ResponseModel extends {
      count?: number
      results?: Array<infer DataModel>
    }
  ? ChangeTypeOfKeys<
      ResponseModel,
      'results',
      NestedSafePick<DataModel, FetchKeysType>[]
    >
  : ResponseModel extends Array<infer DataModel>
  ? NestedSafePick<DataModel, FetchKeysType>[]
  : NestedSafePick<ResponseModel, FetchKeysType>

function postprocessQuery(query?: any) {
  if (!query) return query

  const parsedQuery = query
  if (query.fetchKeys) {
    function getStringifiedQueryKeys(keys: FetchKeysType) {
      let query = '{'
      Object.entries(keys).forEach(([key, value], index) => {
        if (typeof value === 'object') {
          const keyToAdd = `${key}${getStringifiedQueryKeys(value)}`
          query += index > 0 ? `,${keyToAdd}` : keyToAdd
        } else {
          query += index > 0 ? `,${key}` : key
        }
      })
      return query + '}'
    }

    parsedQuery.query = getStringifiedQueryKeys(query.fetchKeys)
  }

  delete parsedQuery.fetchKeys
  return parsedQuery
}

/**
 * @title API* @version 1.0.0 (v1)*/
export class Api<
  SecurityDataType extends unknown
> extends HttpClient<SecurityDataType> {
  admin = {
    /**
     * @description Use with ModelViewSet, or trigger invalidate_cache method manually
     *
     * @tags admin
     * @name AdminPagesPageParentRetrieve
     * @request GET:/admin/pages/page-parent/
     * @secure
     */
    adminPagesPageParentRetrieve: <T extends FetchKeys<AdminsPageParent>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminsPageParent, T>, any>({
        path: `/admin/pages/page-parent/`,
        method: 'GET',
        query: postprocessQuery(query),
        secure: true,
        format: 'json',
        ...params
      })
    /**
     * @description Use with ModelViewSet, or trigger invalidate_cache method manually
     *
     * @tags admin
     * @name AdminPagesPageParentUpdate
     * @request PUT:/admin/pages/page-parent/
     * @secure
     */,
    adminPagesPageParentUpdate: <T extends FetchKeys<AdminsPageParent>>(
      data: AdminsPageParentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminsPageParent, T>, any>({
        path: `/admin/pages/page-parent/`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Use with ModelViewSet, or trigger invalidate_cache method manually
     *
     * @tags admin
     * @name AdminPagesPageParentPartialUpdate
     * @request PATCH:/admin/pages/page-parent/
     * @secure
     */,
    adminPagesPageParentPartialUpdate: <T extends FetchKeys<AdminsPageParent>>(
      data: PatchedAdminsPageParentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminsPageParent, T>, any>({
        path: `/admin/pages/page-parent/`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
  }
  public = {
    /**
     * No description
     *
     * @tags public
     * @name PublicContactMessageCreate
     * @request POST:/public/contact/message/
     */
    publicContactMessageCreate: <T extends FetchKeys<PublicMessage>>(
      data: PublicMessageRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PublicMessage, T>, any>({
        path: `/public/contact/message/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags public
     * @name PublicCoreLanguagesList
     * @request GET:/public/core/languages/
     */,
    publicCoreLanguagesList: <T extends FetchKeys<LanguageChoice[]>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<LanguageChoice[], T>, any>({
        path: `/public/core/languages/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags public
     * @name PublicCoreSettingsRetrieve
     * @request GET:/public/core/settings/
     */,
    publicCoreSettingsRetrieve: <T extends FetchKeys<PublicSiteSettings>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PublicSiteSettings, T>, any>({
        path: `/public/core/settings/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags public
     * @name PublicGalleriesList
     * @request GET:/public/galleries/
     * @secure
     */,
    publicGalleriesList: <T extends FetchKeys<PaginatedPublicGalleryList>>(
      query?: {
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedPublicGalleryList, T>, any>({
        path: `/public/galleries/`,
        method: 'GET',
        query: postprocessQuery(query),
        secure: true,
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags public
     * @name PublicGalleriesRetrieve
     * @request GET:/public/galleries/{id}/
     * @secure
     */,
    publicGalleriesRetrieve: <T extends FetchKeys<PublicGallery>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PublicGallery, T>, any>({
        path: `/public/galleries/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        secure: true,
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags public
     * @name PublicGalleriesGalleryImagesList
     * @request GET:/public/galleries/gallery-images/
     * @secure
     */,
    publicGalleriesGalleryImagesList: <
      T extends FetchKeys<PaginatedPublicGalleryImageList>
    >(
      query?: {
        gallery?: string[]
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedPublicGalleryImageList, T>, any>({
        path: `/public/galleries/gallery-images/`,
        method: 'GET',
        query: postprocessQuery(query),
        secure: true,
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags public
     * @name PublicMenuMainRetrieve
     * @request GET:/public/menu/main/
     */,
    publicMenuMainRetrieve: <T extends FetchKeys<PublicMainMenu>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PublicMainMenu, T>, any>({
        path: `/public/menu/main/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags public
     * @name PublicNewsletterSubscribeCreate
     * @request POST:/public/newsletter/subscribe/
     */,
    publicNewsletterSubscribeCreate: <T extends FetchKeys<PublicSubscribe>>(
      data: PublicSubscribeRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PublicSubscribe, T>, any>({
        path: `/public/newsletter/subscribe/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags public
     * @name PublicPagesArtistsList
     * @request GET:/public/pages/artists/
     */,
    publicPagesArtistsList: <
      T extends FetchKeys<PaginatedPublicArtistListList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedPublicArtistListList, T>, any>({
        path: `/public/pages/artists/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags public
     * @name PublicPagesArtistsRetrieve
     * @request GET:/public/pages/artists/{slug}/
     */,
    publicPagesArtistsRetrieve: <T extends FetchKeys<PublicArtist>>(
      slug: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PublicArtist, T>, any>({
        path: `/public/pages/artists/${slug}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags public
     * @name PublicPagesNewsList
     * @request GET:/public/pages/news/
     */,
    publicPagesNewsList: <T extends FetchKeys<PaginatedPublicNewsList>>(
      query?: {
        is_highlighted?: boolean
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedPublicNewsList, T>, any>({
        path: `/public/pages/news/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags public
     * @name PublicPagesNewsRetrieve
     * @request GET:/public/pages/news/{slug}/
     */,
    publicPagesNewsRetrieve: <T extends FetchKeys<PublicNewsDetail>>(
      slug: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PublicNewsDetail, T>, any>({
        path: `/public/pages/news/${slug}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags public
     * @name PublicPagesSimplePagesList
     * @request GET:/public/pages/simple-pages/
     */,
    publicPagesSimplePagesList: <
      T extends FetchKeys<PaginatedPublicSimplePageResponseList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedPublicSimplePageResponseList, T>, any>({
        path: `/public/pages/simple-pages/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags public
     * @name PublicPagesSimplePagesRetrieve
     * @request GET:/public/pages/simple-pages/{slug}/
     */,
    publicPagesSimplePagesRetrieve: <
      T extends FetchKeys<PublicSimplePageResponse>
    >(
      slug: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PublicSimplePageResponse, T>, any>({
        path: `/public/pages/simple-pages/${slug}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags public
     * @name PublicPagesSitemapRetrieve
     * @request GET:/public/pages/sitemap/
     */,
    publicPagesSitemapRetrieve: <T extends FetchKeys<PagesSitemapResponse>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PagesSitemapResponse, T>, any>({
        path: `/public/pages/sitemap/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags public
     * @name PublicPlacesList
     * @request GET:/public/places/
     */,
    publicPlacesList: <T extends FetchKeys<PaginatedPublicPlaceList>>(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedPublicPlaceList, T>, any>({
        path: `/public/places/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags public
     * @name PublicPlacesRetrieve
     * @request GET:/public/places/{id}/
     */,
    publicPlacesRetrieve: <T extends FetchKeys<PublicPlace>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PublicPlace, T>, any>({
        path: `/public/places/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags public
     * @name PublicUsersTokenLoginCreate
     * @request POST:/public/users/token/login/
     * @secure
     */,
    publicUsersTokenLoginCreate: <T extends FetchKeys<CustomAuthToken>>(
      data: CustomAuthTokenRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CustomAuthToken, T>, any>({
        path: `/public/users/token/login/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
  }
  schema = {
    /**
     * @description OpenApi3 schema for this API. Format can be selected via content negotiation. - YAML: application/vnd.oai.openapi - JSON: application/vnd.oai.openapi+json
     *
     * @tags schema
     * @name SchemaRetrieve
     * @request GET:/schema/
     * @secure
     */
    schemaRetrieve: <T extends FetchKeys<Record<string, any>>>(
      query?: {
        format?: 'json' | 'yaml'
        lang?: 'en' | 'pl'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Record<string, any>, T>, any>({
        path: `/schema/`,
        method: 'GET',
        query: postprocessQuery(query),
        secure: true,
        format: 'json',
        ...params
      })
  }
  schemaPublic = {
    /**
     * @description OpenApi3 schema for this API. Format can be selected via content negotiation. - YAML: application/vnd.oai.openapi - JSON: application/vnd.oai.openapi+json
     *
     * @tags schema-public
     * @name SchemaPublicRetrieve
     * @request GET:/schema-public
     * @secure
     */
    schemaPublicRetrieve: <T extends FetchKeys<Record<string, any>>>(
      query?: {
        format?: 'json' | 'yaml'
        lang?: 'en' | 'pl'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Record<string, any>, T>, any>({
        path: `/schema-public`,
        method: 'GET',
        query: postprocessQuery(query),
        secure: true,
        format: 'json',
        ...params
      })
  }
}
