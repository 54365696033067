import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin(nuxtApp => {
  const router = useRouter()
  const {
    public: { sentry }
  } = useRuntimeConfig()

  if (!sentry.dsn) {
    return
  }

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    environment: sentry.environment,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router)
      })
    ],
    ignoreErrors: [
      'Unable to preload CSS for',
      'Importing a module script failed.',
      '/_nuxt/builds/meta/',
      'Failed to fetch dynamically imported module:',
      'Network Error',
      'Page Not Found'
    ],
    // Performance Monitoring
    tracesSampleRate: sentry.tracesSampleRate
  })

  Sentry.setTag('renderMode', 'client')
})
